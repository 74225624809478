import { create } from 'zustand';

import { Cart, CartItem, Product, SubmittedOrder } from '../core/types/product';

type HandleUpdateCart = {
  changeAmount: number;
  product: Product;
  currentCart: Cart;
  discount: number;
};

const handleUpdateCart = ({
  changeAmount,
  product,
  currentCart,
  discount
}: HandleUpdateCart): Cart => {
  const cartItems = currentCart.cartItems || [];
  const cartProduct = currentCart.cartItems.find(
    ({ productNumber }) => productNumber === product.articleNr
  );
  const isInCart = !!cartProduct;
  const currentCount = cartProduct?.count || 0;
  const isForRemove = changeAmount + currentCount <= 0;

  if (changeAmount < 0 && isInCart && isForRemove) {
    if (isForRemove) {
      const newItems = cartItems.filter(
        ({ productNumber }) => productNumber !== product.articleNr
      );

      return {
        ...currentCart,
        cartItems: newItems
      };
    }
  } else if (changeAmount > 0 && !isInCart) {
    const newItem: CartItem = {
      product,
      discount,
      count: changeAmount,
      productNumber: product.articleNr,
      id: cartItems?.length || 0
    };
    const newItems = [...cartItems, newItem];
    return {
      ...currentCart,
      cartItems: newItems
    };
  } else if (isInCart) {
    const newItem: CartItem = {
      ...cartProduct,
      count: cartProduct.count + changeAmount
    };
    const newItems = cartItems.reduce((result, currentItem) => {
      if (currentItem.productNumber === product.articleNr) {
        return [...result, newItem];
      }
      return [...result, currentItem];
    }, [] as CartItem[]);

    return {
      ...currentCart,
      cartItems: newItems
    };
  }

  return currentCart;
};

interface ShoppingCart {
  cart: Cart | null;
  itemsInCart: number;
  discount: number;
  submittedOrder: SubmittedOrder | null;
  setSubmittedOrder: (order: SubmittedOrder | null) => void;
  setDiscount: (discount: number) => void;
  setCart: (cart: Cart) => void;
  setItemsInCart: (amount: number) => void;
  updateCart: (data: HandleUpdateCart) => void;
}

const useCartStore = create<ShoppingCart>((set) => ({
  cart: null,
  itemsInCart: 0,
  discount: 0,
  submittedOrder: null,
  setSubmittedOrder: (submittedOrder) => set({ submittedOrder }),
  setDiscount: (discount) => set({ discount }),
  setCart: (cart) => set({ cart, itemsInCart: cart?.cartItems?.length || 0 }),
  setItemsInCart: (itemsInCart) => set({ itemsInCart }),
  updateCart: (data) => {
    const cart = handleUpdateCart(data);
    const itemsInCart = cart?.cartItems?.length || 0;

    return set({ cart, itemsInCart });
  }
}));

export default useCartStore;
