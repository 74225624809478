import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { FaIcons, ROUTES } from '../../core/constants';
import useCartStore from '../../store/shoppingCart';
import useUserStore from '../../store/user';
import Counter from '../Counter/Counter';
import FaIcon from '../FaIcon';
import LanguageToggle from '../LanguageToggle/LanguageToggle';
import ShoppingBasketButton from '../ShoppingBasketButton/ShoppingBasketButton';
import AccountDropdown from './AccountDropdown';
import MarketingDropdown from './MarketingDropdown';
import styles from './styles.module.scss';

const getRouteActiveState = (route: string, isExactPath?: boolean) => {
  const currentRoute = window.location.pathname;

  if (isExactPath) {
    return currentRoute === route;
  } else return currentRoute.includes(route);
};

const Header = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'menu' });
  const { itemsInCart } = useCartStore();
  const { unreadNotificationsCount } = useUserStore();
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);

  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <div className={styles.logoWrapper}>
          <button
            className={styles.mobileMenuButton}
            onClick={() => setShowMobileMenu(!showMobileMenu)}
          >
            <FaIcon faName={FaIcons.barsIcon} />
          </button>
          <Link to={ROUTES.assortment}>
            <img
              src="/images/logo-bleijenberg-inverted.svg"
              alt="project logo"
              className={styles.logo}
            />
          </Link>
        </div>

        <div
          className={classNames(styles.headerNav, {
            [styles.mobileMenu]: showMobileMenu
          })}
        >
          <div className={styles.navItems}>
            <Link
              to={ROUTES.assortment}
              className={classNames(styles.navItem, {
                [styles.withCounter]: !!itemsInCart,
                [styles.active]: getRouteActiveState(ROUTES.assortment, true)
              })}
            >
              <FaIcon faName={FaIcons.shopIcon} />
              {t('shop')}
              {!!itemsInCart && (
                <Counter
                  count={itemsInCart}
                  view="green-filled"
                  className={styles.count}
                />
              )}
            </Link>
            <Link
              to="https://www.bleijenbergwijnen.be"
              target="_blank"
              className={styles.navItem}
            >
              <FaIcon faName={FaIcons.wineGlassIcon} />
              {t('wines')}
            </Link>
            <MarketingDropdown />
            <Link
              to={ROUTES.notifications}
              className={classNames(styles.navItem, {
                [styles.withCounter]: !!unreadNotificationsCount,
                [styles.active]: getRouteActiveState(ROUTES.notifications, true)
              })}
            >
              <FaIcon faName={FaIcons.messageIcon} />
              {t('messages')}
              {!!unreadNotificationsCount && (
                <Counter
                  count={unreadNotificationsCount}
                  view="red"
                  className={styles.count}
                />
              )}
            </Link>
            <AccountDropdown />
          </div>
          <LanguageToggle
            light
            dropdownView
            className={styles.desktopLanguageToggle}
          />
          <LanguageToggle className={styles.mobileLanguageToggle} />
        </div>
        <div className={styles.mobileBasket}>
          <ShoppingBasketButton />
        </div>
      </div>
    </header>
  );
};

export default Header;
