import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { logout } from '../../core/api/user';
import { FaIcons, ROUTES, STORAGE_KEYS } from '../../core/constants';
import useUserStore from '../../store/user';
import Button from '../Button/Button';
import FaIcon from '../FaIcon';
import styles from './styles.module.scss';

const AccountDropdown = () => {
  const { email } = useUserStore();
  const { t } = useTranslation('translation', { keyPrefix: 'menu' });
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleLogout = async () => {
    try {
      await logout();

      localStorage.removeItem(STORAGE_KEYS.auth_token);
      navigate(ROUTES.login);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className={styles.accountDropdown}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <button className={styles.accountDropdownBtn}>
        <FaIcon faName={FaIcons.userIcon} className={styles.accountIcon} />
        {t('my-account')}
        <FaIcon faName={FaIcons.chevronDown} className={styles.chevronIcon} />
      </button>
      <div
        className={classNames(styles.dropdownItems, {
          [styles.open]: isOpen
        })}
      >
        <Link to={ROUTES.itemsOnOrder} className={styles.item}>
          <FaIcon faName={FaIcons.chevronDown} />
          {t('ordered-items')}
        </Link>
        <Link to={ROUTES.purchaseHistory} className={styles.item}>
          <FaIcon faName={FaIcons.chevronDown} />
          {t('purchase-history')}
        </Link>
        <Link to={ROUTES.invoices} className={styles.item}>
          <FaIcon faName={FaIcons.chevronDown} />
          {t('invoice')}
        </Link>
        <Link to={ROUTES.itemsReturn} className={styles.item}>
          <FaIcon faName={FaIcons.chevronDown} />
          {t('return-items')}
        </Link>
        <Button
          buttonText={t('sign-out')}
          onClick={handleLogout}
          view="dark"
          className={styles.accountBtn}
        />
        <p className={styles.userEmail}>{email}</p>
      </div>
    </div>
  );
};

export default AccountDropdown;
