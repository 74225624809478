import classNames from 'classnames';
import { ReactNode } from 'react';

import Loader from '../Loader/Loader';
import styles from './Button.module.scss';

const Button = ({
  type = 'button',
  view = 'primary',
  onClick,
  buttonText,
  className = '',
  disabled = false,
  children,
  isLoading
}: {
  type?: 'button' | 'submit';
  view?: 'primary' | 'secondary' | 'dark' | 'transparent' | 'green';
  onClick?: () => void;
  buttonText?: string | number;
  className?: string;
  disabled?: boolean;
  children?: ReactNode;
  isLoading?: boolean;
}) => {
  const content = buttonText || children;

  return (
    <button
      className={classNames(styles.button, className, styles[view])}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {isLoading ? <Loader size={20} /> : content}
    </button>
  );
};

export default Button;
