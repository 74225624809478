import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import MainLayout from '../../components/MainLayout/MainLayout';
import OrderInfo from '../../components/OrderInfo/OrderInfo';
import OrderTable from '../../components/OrderTable/OrderTable';
import { getOrderOverviewColumns, ROUTES } from '../../core/constants';
import { SubmittedProduct } from '../../core/types/product';
import useCartStore from '../../store/shoppingCart';
import styles from './styles.module.scss';

const OrderPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { submittedOrder } = useCartStore();

  const formattedDeliveryDate = moment(submittedOrder?.deliveryDate).format(
    'DD/MM/yyyy'
  );

  return (
    <MainLayout>
      <div className={styles.orderPage}>
        <OrderInfo
          title={t('order-overview.title')}
          backButtonText={t('order-overview.back-btn-title')}
          backButtonAction={() => navigate(ROUTES.assortment)}
          orderNumber={submittedOrder?.orderNumber}
          subTitle={
            <>
              {t('order-overview.sub-title')}{' '}
              <b>{submittedOrder?.customerEmail}</b>
            </>
          }
        />
        <OrderTable<SubmittedProduct>
          titleView="green"
          titleMainText={`${t('order-overview.ordered-on')} ${formattedDeliveryDate}`}
          // titleSubText="PDF"
          // titleIcon={FaIcons.printIcon}
          tableProps={{
            columns: getOrderOverviewColumns(t),
            data: submittedOrder?.items as SubmittedProduct[]
          }}
        />
      </div>
    </MainLayout>
  );
};

export default OrderPage;
