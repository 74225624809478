import { create } from 'zustand';

import { ASSORTMENT_ARTICLES, SortOptions } from '../core/constants';
import { RawCategory } from '../core/types/category';
import { ArticleItem } from '../core/types/product';

type ProductFilters = {
  search: string;
  categories: string[];
  article: ArticleItem;
  selectedCategoryItems: RawCategory[];
  categoryName: string;
};

interface ProductsStore {
  currentPage: number;
  currentBookmarkKey: string | null;
  changeBookmarkKey: (newKey: string | null) => void;
  pageLimit: number;
  totalCount: number;
  isLoading: boolean;
  changePage: (page: number) => void;
  changeTotalCount: (count: number) => void;
  filters: ProductFilters;
  changeFilters: (changedFilters: Partial<ProductFilters>) => void;
  sorting: SortOptions;
  changeSorting: (sortOption: SortOptions) => void;
  hasNextPage: boolean;
  setHasNextPage: (value: boolean) => void;
  toggleLoading: (state?: boolean) => void;
}

const useProductsStore = create<ProductsStore>((set) => ({
  currentPage: 1,
  currentBookmarkKey: null,
  pageLimit: 20,
  totalCount: 0,
  isLoading: false,
  changePage: (page: number) => set({ currentPage: page }),
  changeBookmarkKey: (newKey: string | null) =>
    set({ currentBookmarkKey: newKey }),
  changeTotalCount: (count: number) => set({ totalCount: count }),
  toggleLoading: (newState) => {
    if (typeof newState !== 'undefined')
      return set(() => ({ isLoading: newState }));
    return set((state) => ({ isLoading: !state.isLoading }));
  },
  filters: {
    search: '',
    categories: [],
    selectedCategoryItems: [],
    categoryName: '',
    article: ASSORTMENT_ARTICLES[0]
  },
  changeFilters: (changedFilters: Partial<ProductFilters>) =>
    set((state) => ({
      ...state,
      currentPage: 1,
      filters: { ...state.filters, ...changedFilters },
      currentBookmarkKey: null
    })),
  sorting: SortOptions.productsInStock,
  changeSorting: (sorting) => set({ sorting, currentBookmarkKey: null }),
  hasNextPage: false,
  setHasNextPage: (value) => set({ hasNextPage: value })
}));

export default useProductsStore;
