import i18n from '../../i18n.config';
import { ERRORS, OrderStatus } from '../constants';
import { Order } from '../types/product';
import apiClient from './client';

export const getOrder = async (id: number): Promise<Order | Error> => {
  const { statusCode, response } = await apiClient.get<Order>(
    `api/orders/${id}`
  );

  if (statusCode === 200) {
    return response;
  } else {
    throw new Error(i18n.t(ERRORS.default));
  }
};

export const getOrders = async (
  status: OrderStatus,
  bookmarkKey?: string
): Promise<Order[]> => {
  const queries = new URLSearchParams(
    JSON.parse(
      JSON.stringify({
        status,
        bookmarkKey
      })
    )
  );
  const { statusCode, response } = await apiClient.get<Order[]>(
    `api/orders?${queries}`
  );

  if (statusCode === 200) {
    return response;
  } else {
    throw new Error(i18n.t(ERRORS.default));
  }
};
