import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { FaIcons, ROUTES } from '../../core/constants';
import useCartStore from '../../store/shoppingCart';
import Counter from '../Counter/Counter';
import DropdownBlock from '../DropdownBlock/DropdownBlock';

const ShoppingBasketButton = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'menu' });
  const navigate = useNavigate();
  const { itemsInCart } = useCartStore();

  const counterOptions: ComponentProps<typeof Counter> | undefined = itemsInCart
    ? {
        count: itemsInCart,
        view: 'red'
      }
    : undefined;

  return (
    <DropdownBlock
      view="green"
      title={t('articles-in')}
      value={t('shopping-basket')}
      iconName={FaIcons.basketShopping}
      counterOptions={counterOptions}
      onClick={() => navigate(ROUTES.shoppingBasket)}
    />
  );
};

export default ShoppingBasketButton;
