import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Product } from '../../core/types/product';
import useListViewStore from '../../store/listView';
import useProductsStore from '../../store/products';
import Button from '../Button/Button';
import FiltersBreadcrumbs from '../Filters/FiltersBreadcrumbs';
import FiltersSideBar from '../Filters/FiltersSideBar';
import ProductCard from '../ProductCard/ProductCard';
import PromotedProductCarousel from './PromotedProductCarousel';
import SortDropdown from './SortDropdown';
import styles from './styles.module.scss';
import ViewToggle from './ViewToggle';

const ProductsList = ({ products }: { products: Product[] }) => {
  const { t } = useTranslation('translation');
  const {
    filters: { article }
  } = useProductsStore();
  const listView = useListViewStore((state) => state.productsListView);
  const { isLoading, changeBookmarkKey, hasNextPage } = useProductsStore();
  const nextPageKey = products[products.length - 1]?.bookmarkKey;

  return (
    <div className={styles.productsList}>
      <PromotedProductCarousel />
      <section className={styles.listTopBar}>
        <div className={styles.sortWrapper}>
          <div className={styles.mobileFilterBar}>
            <FiltersSideBar />
          </div>
          <span className={classNames(styles.articleValue, styles.desktopOnly)}>
            {t(article.title)}
          </span>
          <SortDropdown className={styles.desktopOnly} />
        </div>
        <div>
          <ViewToggle />
        </div>
      </section>
      <FiltersBreadcrumbs />
      <div className={classNames(styles.itemsList, styles[listView])}>
        {products.map((product) => (
          <ProductCard
            key={product.articleNr}
            product={product}
            view={listView}
          />
        ))}
      </div>
      {hasNextPage && (
        <Button
          view="secondary"
          buttonText={t('pagination-title')}
          onClick={() => changeBookmarkKey(nextPageKey)}
          disabled={isLoading}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default ProductsList;
