import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getCategories } from '../../../core/api/categories';
import { FaIcons } from '../../../core/constants';
import { FilterCategory, RawCategory } from '../../../core/types/category';
import useClickOutside from '../../../hooks/useClickOutside';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import useProductsStore from '../../../store/products';
import FaIcon from '../../FaIcon';
import CategoryItem from './CategoryItem';
import styles from './styles.module.scss';

const getSearchSequence = (
  searchedCategory: RawCategory,
  categories: FilterCategory[],
  index: number
): RawCategory[] => {
  const currentCategory = categories[index];

  if (!categories[index]) return [];

  const categoryToSave = {
    name: currentCategory.name,
    codes: currentCategory.codes
  };

  const isSameLength =
    currentCategory.codes.length === searchedCategory.codes.length;
  const isSameCombination = currentCategory.codes.every((code) =>
    searchedCategory.codes.includes(code)
  );
  const isSameName = currentCategory.name === searchedCategory.name;
  const isEqual = isSameLength && isSameCombination && isSameName;

  if (isEqual) {
    return [categoryToSave];
  } else if (currentCategory?.subCategories?.length) {
    const children = getSearchSequence(
      searchedCategory,
      currentCategory.subCategories,
      0
    );

    if (children?.length) return [categoryToSave, ...children];
  }

  return getSearchSequence(searchedCategory, categories, index + 1);
};

const CategoryFilter = () => {
  const { t } = useTranslation('translation');
  const {
    changeFilters,
    filters: { categories: savedCategories }
  } = useProductsStore();
  const [categoriesList, setCategoriesList] = useState<RawCategory[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchResults, setSearchResults] = useState<RawCategory[]>([]);
  const [categories, setCategories] = useState<FilterCategory[]>([]);
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);
  const ref = useClickOutside(() => {
    setIsSearchActive(false);
  });

  const resetCategories = () => {
    changeFilters({ categories: [], selectedCategoryItems: [] });
  };

  const fetchCategories = async () => {
    try {
      const categoriesData = await getCategories();
      const formattedCategories = categoriesData.tree.map(
        ({ codes, category: name, subcategories }) => ({
          codes,
          name,
          subCategories: subcategories.map(
            ({ codes, subcategory: name, sub_subcategories }) => ({
              codes,
              name,
              subCategories: sub_subcategories.map(
                ({ codes, sub_subcategory: name }) => ({
                  codes,
                  name
                })
              )
            })
          )
        })
      );

      setCategories(formattedCategories);
      setCategoriesList(categoriesData.list);
      setSearchResults(categoriesData.list);
    } catch (error) {
      console.error('categories fetching error', error);
    }
  };

  const saveCategoriesBrunch = (categoriesSequence: RawCategory[]) => {
    changeFilters({ selectedCategoryItems: categoriesSequence });
  };

  const searchCategory = (query: string) => {
    const filteredCategories = categoriesList.filter((category) => {
      const formattedName = category.name.toLowerCase();
      const formattedQuery = query.toLowerCase();

      return formattedName.includes(formattedQuery);
    });

    setSearchResults(filteredCategories);
  };

  const handleSearchCategory = useDebouncedCallback(
    (query: string) => searchCategory(query),
    300
  );

  const handleSearchEnd = (selectedCategory: RawCategory) => {
    const categoriesSequence = getSearchSequence(
      selectedCategory,
      categories,
      0
    );

    changeFilters({ selectedCategoryItems: categoriesSequence });
    setIsSearchActive(false);
    setSearchQuery('');
  };

  useEffect(() => {
    if (isSearchActive) {
      handleSearchCategory(searchQuery);
    }
  }, [searchQuery, isSearchActive]);

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div className={styles.categoryFilter} ref={ref}>
      <div className={styles.categoryInputWrapper}>
        <input
          className={styles.categoryInput}
          placeholder={t('filters.categories-placeholder')}
          onChange={(event) => setSearchQuery(event.target.value)}
          onClick={() => setIsSearchActive(!isSearchActive)}
          value={searchQuery}
        />
        {!!savedCategories.length && (
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              resetCategories();
            }}
            className={styles.resetButton}
          >
            RESET
          </button>
        )}
        <FaIcon
          faName={FaIcons.chevronDown}
          className={styles.categoryInputIcon}
        />
      </div>
      {isSearchActive ? (
        <ul>
          {searchResults.map((category) => {
            const key =
              category.name + category.codes.toString().split(' ').join('-');

            return (
              <CategoryItem
                key={key}
                category={category}
                saveCategoriesBrunch={(categories: RawCategory[]) =>
                  handleSearchEnd(categories[0])
                }
              />
            );
          })}
        </ul>
      ) : (
        <ul>
          {!!categories.length &&
            categories.map((category) => {
              const key =
                category.name + category.codes.toString().split(' ').join('-');

              return (
                <CategoryItem
                  key={key}
                  category={category}
                  saveCategoriesBrunch={(items) =>
                    saveCategoriesBrunch([...items])
                  }
                />
              );
            })}
        </ul>
      )}
    </div>
  );
};

export default CategoryFilter;
