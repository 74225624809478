import i18n from '../../i18n.config';
import useProductsStore from '../../store/products';
import {
  ArticlesItems,
  ERRORS,
  ReturnRequestStatus,
  SortOptions
} from '../constants';
import {
  Product,
  ProductPromotion,
  RequestedReturn,
  ReturnReason
} from '../types/product';
import apiClient from './client';

const SortOrders = {
  [SortOptions.productsInStock]: 'in_stock',
  [SortOptions.bestProducts]: 'best'
};

const AssortmentFilterQueries = {
  [ArticlesItems.fullRange]: {},
  [ArticlesItems.favorite]: {},
  [ArticlesItems.myOrderList]: { assortment: true },
  [ArticlesItems.promotions]: { promotion: true },
  [ArticlesItems.quickSale]: {}
};

export const getProducts = async (): Promise<Product[] | Error> => {
  const { pageLimit, currentBookmarkKey, filters, sorting, setHasNextPage } =
    useProductsStore.getState();
  const searchKey = isNaN(Number(filters?.search)) ? `name` : 'articleNr';
  const mappedCategories: string[] = filters.categories.map(
    (category, index) => `categories[${index}]=${category}`
  );
  const categories = mappedCategories.join('&');
  const categoriesQuery = filters.categories.length ? `&${categories}` : '';

  const queries = new URLSearchParams(
    JSON.parse(
      JSON.stringify({
        ...(currentBookmarkKey ? { bookmarkKey: currentBookmarkKey } : {}),
        pageSize: pageLimit + 1,
        ...(filters.search ? { [searchKey]: filters.search } : {}),
        sortOn: SortOrders[sorting],
        ...(AssortmentFilterQueries[filters.article.value] || {})
      })
    )
  );

  const { statusCode, response } = await apiClient.get<Product[]>(
    `api/products?${queries}${categoriesQuery}`
  );

  if (statusCode === 200) {
    const fetchedProducts = response;

    setHasNextPage(fetchedProducts.length === pageLimit + 1);

    const products = fetchedProducts.slice(0, pageLimit);
    return products;
  } else {
    throw new Error(i18n.t(ERRORS.default));
  }
};

export const getPromotions = async (): Promise<ProductPromotion[] | Error> => {
  const { statusCode, response } =
    await apiClient.get<ProductPromotion[]>('api/promotions');

  if (statusCode === 200) return response;
  else throw new Error(i18n.t(ERRORS.default));
};

type CustomerSheetRequest = {
  search: string;
};

export const getCustomerProducts = async ({
  search
}: CustomerSheetRequest): Promise<Product[] | Error> => {
  const searchKey = isNaN(Number(search)) ? `translations.name` : 'articleNr';
  const query = search ? `&${searchKey}=${search}` : '';

  const { statusCode, response } = await apiClient.get<Product[]>(
    `api/products?my_order_list=true${query}`
  );

  if (statusCode === 200) return response;
  else throw new Error(i18n.t(ERRORS.default));
};

export const requestReturnItems = async ({
  itemsForReturn
}: {
  itemsForReturn: FormData;
}): Promise<void | Error> => {
  const headers = {
    ['Accept']: 'multipart/form-data'
  };

  const { statusCode } = await apiClient.post('api/returns/submit', {
    body: itemsForReturn,
    headers
  });

  if (statusCode === 201) return;
  else throw new Error(i18n.t(ERRORS.default));
};

export const getRequestedReturns = async (
  status: ReturnRequestStatus
): Promise<RequestedReturn[] | Error> => {
  const query = `?status=${status}`;
  const { statusCode, response } = await apiClient.get<RequestedReturn[]>(
    `api/returns${query}`
  );

  if (statusCode === 200) {
    return response;
  } else {
    throw new Error(i18n.t(ERRORS.default));
  }
};

export const removeItemFromReturnRequest = async (
  orderId: number,
  itemId: number
): Promise<void | Error> => {
  const { statusCode } = await apiClient.delete(
    `api/returns/${orderId}/item/${itemId}`
  );

  if (statusCode === 204) return;

  throw new Error(i18n.t(ERRORS.default));
};

export const addToFavorite = async (
  articleNr: string
): Promise<void | Error> => {
  const { statusCode } = await apiClient.post('api/favorite-products/add', {
    body: JSON.stringify({ articleNr })
  });

  if (statusCode === 200) return;
  else {
    throw new Error(i18n.t(ERRORS.default));
  }
};

export const removeFromFavorite = async (
  articleNr: string
): Promise<void | Error> => {
  const { statusCode } = await apiClient.post('api/favorite-products/remove', {
    body: JSON.stringify({ articleNr })
  });

  if (statusCode === 200) return;
  else {
    throw new Error(i18n.t(ERRORS.default));
  }
};

export const getReturnReasons = async (): Promise<ReturnReason[] | Error> => {
  const { response, statusCode } = await apiClient.get<ReturnReason[]>(
    'api/returns/return-reasons'
  );

  if (statusCode === 200) {
    return response;
  } else {
    throw new Error(i18n.t(ERRORS.default));
  }
};

export const addToMyAssortmentList = async (articleNr: string) => {
  const { statusCode } = await apiClient.post('api/my-assortment/add', {
    body: JSON.stringify({ articleNr })
  });

  if (statusCode === 200) {
    return;
  } else {
    throw new Error(i18n.t(ERRORS.default));
  }
};

export const removeFromMyAssortmentList = async (articleNr: string) => {
  const { statusCode } = await apiClient.post('api/my-assortment/remove', {
    body: JSON.stringify({ articleNr })
  });

  if (statusCode === 200) {
    return;
  } else {
    throw new Error(i18n.t(ERRORS.default));
  }
};
