import { AvailableLocales } from '../constants';

export enum UserRoles {
  Client = 'CLIENT',
  groupAdmin = 'GROUP_ADMIN',
  marketing = 'MARKETING',
  superAdmin = 'SUPER_ADMIN'
}

export type Customer = {
  id: number;
  username: string;
  email: string;
  name: string;
  enabled: boolean;
};

export type User = {
  user: {
    id: number;
    email: string;
    admin: boolean;
    roles: UserRoles[];
  };
  unreadCount: number;
  activeCustomer: Customer;
  customers: Customer[];
};

// export type Invoice = {
//   id: number;
//   invoiceNumber: string;
//   structuredCommunication: string;
//   amount: number;
//   invoiceDate: string;
//   isPaid: boolean;
//   dueDate: string;
//   reference: string;
//   remaining_amount: number;
//   total_amount: number;
// };

export type Invoice = {
  bookmarkKey: string;
  dueDate: string;
  id: number;
  invoiceDate: string;
  invoiceNumber: string;
  isPaid: boolean;
  remainingAmount: string;
  structuredCommunication: string;
  totalAmount: string;
};

export type Notification = {
  id: number;
  notification: string;
  notificationDate: string;
  notificationType: number;
  title: string;
  isRead: boolean;
};

export type NotificationItem = Notification & {
  isSelected: boolean;
};

export type NotificationTranslation = {
  translatable: string;
  title: string;
  notification: string;
  locale: AvailableLocales;
};

export type FullNotification = {
  id: number;
  title: string;
  notification: string;
  notificationDate: string;
  translations: { [key: string]: NotificationTranslation };
};

export type FullNotificationItem = FullNotification & {
  isSelected: boolean;
};

export type EditedNotification = {
  translations: { [key: string]: NotificationTranslation };
};
