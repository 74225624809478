import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { fetchMyCustomers, setActiveCustomer } from '../../../core/api/user';
import { FaIcons, PERMISSIONS } from '../../../core/constants';
import { Customer } from '../../../core/types/user';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import useRoles from '../../../hooks/useRoles';
import useUserStore from '../../../store/user';
import Button from '../../Button/Button';
import Input from '../../Input/Input';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import CustomerItem from './CustomerItem';
import styles from './styles.module.scss';

const MultiUserModal = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'multi-user' });
  const {
    customersData: { list: userCustomers, isLastListPage }
  } = useUserStore();
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { hasPermissions } = useRoles(PERMISSIONS.multiUser);

  const startSearch = useDebouncedCallback((value: string) => {
    setSearchQuery(value);
    setCurrentPage(1);
  }, 500);

  const handleChangeSearch = (value: string) => {
    setSearchValue(value);
    startSearch(value);
  };

  const changeActiveCustomer = async (customer: Customer) => {
    try {
      await setActiveCustomer(customer);

      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchMyCustomers({
      page: currentPage,
      search: searchQuery
    });
  }, [currentPage, searchQuery]);

  if (!hasPermissions) return null;

  return (
    <ModalWrapper onClose={onClose}>
      <div className={styles.multiUserWrapper}>
        <div className={styles.multiUser}>
          <div className={styles.description}>
            <span className={styles.title}>{t('title')}</span>
            <span className={styles.text}>{t('sub-title')}</span>
          </div>
          <Input
            name="search"
            inputClassName={styles.userSearch}
            placeholder={t('search-placeholder')}
            value={searchValue}
            onChange={(e) => handleChangeSearch(e.target.value)}
            Icon={FaIcons.barsFilterIcon}
            iconClassName={styles.filterIcon}
            wrapperClassName={styles.searchWrapper}
          />
          <div className={styles.customers}>
            {userCustomers.map((customer) => (
              <CustomerItem
                customer={customer}
                key={customer.id}
                changeActiveCustomer={changeActiveCustomer}
              />
            ))}
            {!isLastListPage && (
              <Button
                view="secondary"
                buttonText={t('load-more')}
                onClick={() => setCurrentPage(currentPage + 1)}
              />
            )}
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default MultiUserModal;
