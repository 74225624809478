import { useEffect, useState } from 'react';

import { UserRoles } from '../core/types/user';
import useUserStore from '../store/user';

export const checkPermission = (rolesWithPermission: UserRoles[]) => {
  const userRoles = useUserStore.getState().userRoles;

  return userRoles.some((role) => rolesWithPermission.includes(role));
};

const useRoles = (rolesWithPermissions: UserRoles[]) => {
  const { userRoles } = useUserStore();
  const [hasPermissions, setHasPermissions] = useState<boolean>(
    checkPermission(rolesWithPermissions)
  );

  useEffect(() => {
    setHasPermissions(checkPermission(rolesWithPermissions));
  }, [userRoles]);

  return {
    hasPermissions
  };
};

export default useRoles;
