import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { getActiveCart } from '../../core/api/cart';
import { fetchActiveCustomer, getCurrentUser } from '../../core/api/user';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import styles from './styles.module.scss';

const MainLayout = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const { i18n } = useTranslation('translation');
  const fetchGeneralData = async () => {
    try {
      await fetchActiveCustomer();
      await getActiveCart();
      await getCurrentUser();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchGeneralData();
  }, [i18n.language]);

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <div className={styles.mainLayout}>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default MainLayout;
