import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FaIcons, PERMISSIONS } from '../../core/constants';
import useRoles from '../../hooks/useRoles';
import useUserStore from '../../store/user';
import DropdownBlock from '../DropdownBlock/DropdownBlock';
import MultiUserModal from '../Modals/MultiUser/MultiUser';

const MultiUserSelect = ({
  view,
  className
}: {
  view: 'light' | 'lg-transparent';
  className?: string;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'menu' });
  const {
    customersData: { active: activeCustomer }
  } = useUserStore();
  const [showMultiUser, setShowMultiUser] = useState<boolean>(false);

  const { hasPermissions } = useRoles(PERMISSIONS.multiUser);

  if (!hasPermissions) return <></>;

  return (
    <>
      <DropdownBlock
        view={view}
        title={t('multi-user-title')}
        value={activeCustomer?.name || ''}
        iconName={FaIcons.arrowsRightLeft}
        onClick={() => setShowMultiUser(true)}
        wrapperClassName={className}
      />
      {showMultiUser && (
        <MultiUserModal onClose={() => setShowMultiUser(false)} />
      )}
    </>
  );
};

export default MultiUserSelect;
