import { create } from 'zustand';

import { Customer, UserRoles } from '../core/types/user';

type CustomersData = {
  active: Customer | null;
  list: Customer[];
  isLastListPage: boolean;
};

interface UserStore {
  customersData: CustomersData;
  updateCustomersData: (data: Partial<CustomersData>) => void;
  unreadNotificationsCount: number;
  setUnreadNotificationsCount: (count: number) => void;
  email: string;
  setEmail: (state: string) => void;
  userRoles: UserRoles[];
  setUserRoles: (roles: UserRoles[]) => void;
}

const useUserStore = create<UserStore>((set) => ({
  customersData: {
    active: null,
    list: [],
    isLastListPage: true
  },
  updateCustomersData: (data) =>
    set((state) => ({ customersData: { ...state.customersData, ...data } })),
  unreadNotificationsCount: 0,
  setUnreadNotificationsCount: (unreadNotificationsCount) =>
    set({ unreadNotificationsCount }),
  email: '',
  setEmail: (email) => set({ email }),
  userRoles: [],
  setUserRoles: (userRoles) => set({ userRoles })
}));

export default useUserStore;
