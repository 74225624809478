import i18n from '../../i18n.config';
import useUserStore from '../../store/user';
import { ERRORS, STORAGE_KEYS } from '../constants';
import { Customer, Invoice, User } from '../types/user';
import apiClient from './client';

type AuthResponse = {
  token: string;
  message: string;
};

export const getCurrentUser = async () => {
  const { statusCode, response } = await apiClient.get<User>('api/users/me');

  if (statusCode === 200) {
    useUserStore.getState().setUnreadNotificationsCount(response.unreadCount);
    useUserStore.getState().setEmail(response.user.email);
    useUserStore.getState().setUserRoles(response.user.roles);
    return;
  } else {
    throw new Error(i18n.t(ERRORS.default));
  }
};

export const authUser = async (requestData: {
  email: string;
  password: string;
}) => {
  const {
    statusCode,
    response: { message, token }
  } = await apiClient.post<AuthResponse>('login', {
    body: JSON.stringify(requestData)
  });
  if (statusCode === 200) {
    localStorage.setItem(STORAGE_KEYS.auth_token, token);
    return;
  } else if (statusCode === 401) {
    throw new Error(message);
  }
  throw new Error(message || i18n.t(ERRORS.default));
};

export const logout = async () => {
  const { statusCode } = await apiClient.post('api/logout');

  if (statusCode === 200) return;
  else throw new Error(i18n.t(ERRORS.default));
};

export const forgetPassword = async (requestData: { email: string }) => {
  const { statusCode } = await apiClient.post('forgot-password/', {
    body: JSON.stringify(requestData)
  });

  if (statusCode === 204) return;
  throw new Error(i18n.t(ERRORS.default));
};

export const checkResetTokenValidity = async (token: string) => {
  const { statusCode } = await apiClient.get(`forgot-password/${token}`);

  if (statusCode === 200) return;
  throw new Error(i18n.t(ERRORS.default));
};

export const setNewPassword = async ({
  password,
  token
}: {
  password: string;
  token: string;
}) => {
  const { statusCode } = await apiClient.post(`forgot-password/${token}`, {
    body: JSON.stringify({ password })
  });

  if (statusCode === 204) return;

  throw new Error(i18n.t(ERRORS.default));
};

export const fetchMyCustomers = async ({
  page,
  search
}: {
  page: number;
  search: string;
}): Promise<void | Error> => {
  const queries = new URLSearchParams(
    JSON.parse(
      JSON.stringify({
        search,
        page,
        pageSize: 10
      })
    )
  );
  const { statusCode, response } = await apiClient.get<Customer[]>(
    `api/users/my-customers?${queries}`
  );

  if (statusCode === 200) {
    const isLastListPage = response.length < 10;
    const list = response;
    if (page > 1) {
      const currentList = useUserStore.getState().customersData.list;
      list.unshift(...currentList);
    }
    useUserStore.getState().updateCustomersData({ list, isLastListPage });
    return;
  }

  throw new Error(i18n.t(ERRORS.default));
};

export const fetchActiveCustomer = async (): Promise<void | Error> => {
  const { statusCode, response } = await apiClient.get<Customer>(
    'api/users/get-active-customer'
  );

  if (statusCode === 200) {
    useUserStore.getState().updateCustomersData({ active: response });
  }
};

export const setActiveCustomer = async (
  customer: Customer
): Promise<void | Error> => {
  const { statusCode } = await apiClient.post('api/users/set-active-customer', {
    body: JSON.stringify({ customer_id: customer.id })
  });

  if (statusCode === 200) {
    useUserStore.getState().updateCustomersData({ active: customer });
  }
};

export const getInvoices = async (): Promise<Invoice[] | Error> => {
  const { statusCode, response } =
    await apiClient.get<Invoice[]>('api/invoices');

  if (statusCode === 200) {
    return response;
  }

  throw new Error(i18n.t(ERRORS.default));
};

export const downloadInvoicePDF = async (
  invoiceNumber: string
): Promise<any> => {
  const { statusCode, response } = await apiClient.get(
    `api/invoices/${invoiceNumber}/download`
  );

  if (statusCode === 200) {
    const blob = await response.blob();
    const fileUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = fileUrl;
    downloadLink.download = `${invoiceNumber}.pdf`;

    document.body.appendChild(downloadLink);
    downloadLink.click();

    URL.revokeObjectURL(fileUrl);
    document.body.removeChild(downloadLink);

    return;
  }

  throw new Error(i18n.t(ERRORS.default));
};
