import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/Button/Button';
import MainLayout from '../../components/MainLayout/MainLayout';
import OrderHeader from '../../components/OrderHeader/OrderHeader';
import OrderTable from '../../components/OrderTable/OrderTable';
import { getOrders } from '../../core/api/order';
import { getOrderOverviewColumns, OrderStatus } from '../../core/constants';
import { ItemInOrder, Order } from '../../core/types/product';
import styles from './styles.module.scss';

const ItemsOnOrderPage = () => {
  const { t } = useTranslation('translation');
  const [orders, setOrders] = useState<Order[]>([]);
  const [currentBookmarkKey, setCurrentBookmarkKey] = useState<string>('');
  const [isLastPage, setIsLastPage] = useState<boolean>(false);

  const fetchOrders = async () => {
    try {
      const fetchedOrders = await getOrders(
        OrderStatus.active,
        currentBookmarkKey
      );

      if (currentBookmarkKey) {
        setOrders([...orders, ...fetchedOrders]);
      } else setOrders(fetchedOrders);

      const lastOrderItems = fetchedOrders?.[fetchedOrders.length - 1]?.items;
      const bookmarkKey =
        lastOrderItems?.[lastOrderItems.length - 1]?.bookmarkKey;
      setCurrentBookmarkKey(bookmarkKey);
      setIsLastPage(!fetchedOrders.length);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <MainLayout>
      <div className={styles.itemsOnOrder}>
        <OrderHeader
          title={t('items-on-order.title')}
          backButtonTitle={t('items-on-order.back-btn-title')}
          // titleButtonContent={
          //   <>
          //     PDF <FaIcon faName={FaIcons.printIcon} />
          //   </>
          // }
        />
        <section className={styles.orders}>
          {orders.map((currentOrder) => {
            const formattedDate = moment(currentOrder.deliveryDate).format(
              'DD/MM/YYYY'
            );
            const titleText = `${t('items-on-order.delivery-date')} ${formattedDate}`;

            return (
              <OrderTable<ItemInOrder>
                key={currentOrder.orderNumber}
                titleView="orange"
                titleMainText={titleText}
                tableProps={{
                  columns: getOrderOverviewColumns(t),
                  data: currentOrder.items
                }}
              />
            );
          })}
          {!isLastPage && (
            <Button
              buttonText={t('items-on-order.load-more')}
              view="secondary"
              onClick={fetchOrders}
            />
          )}
        </section>
      </div>
    </MainLayout>
  );
};

export default ItemsOnOrderPage;
