import { Oval } from 'react-loader-spinner';

// import styles from './styles.module.scss';

interface ILoaderProps {
  size: number;
  className?: string;
  view?: 'default' | 'light';
}

const viewConfig: {
  [key: string]: {
    color: string;
    secondaryColor: string;
  };
} = {
  default: {
    color: '#9e9e9e',
    secondaryColor: '#bcbcbc'
  },
  light: {
    color: '#f8f8f8',
    secondaryColor: '#eeeeee'
  }
};

const Loader = ({ size, view = 'default' }: ILoaderProps) => (
  <Oval
    visible={true}
    height={size}
    width={size}
    ariaLabel="oval-loading"
    strokeWidth={5}
    {...(viewConfig[view] || {})}
  />
);

export default Loader;
