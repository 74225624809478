import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { FaIcons } from '../../../core/constants';
import { FilterCategory, RawCategory } from '../../../core/types/category';
import useProductsStore from '../../../store/products';
import FaIcon from '../../FaIcon';
import styles from './styles.module.scss';

const CategoryItem = ({
  category: { name, codes, subCategories },
  saveCategoriesBrunch
}: {
  category: FilterCategory;
  saveCategoriesBrunch: (items: RawCategory[]) => void;
}) => {
  const {
    changeFilters,
    filters: { categories }
  } = useProductsStore();
  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);

  const handleClick = () => {
    setIsListOpen(!isListOpen);

    if (!isActive) {
      const sequenceItem = {
        name,
        codes
      };

      changeFilters({ categories: codes });
      saveCategoriesBrunch([sequenceItem]);
    }
  };

  useEffect(() => {
    const isSameCombination = codes.every((code) => categories.includes(code));
    const isSameLength = codes.length === categories.length;

    setIsActive(isSameLength && isSameCombination);
  }, [categories]);

  const haveSubCategories = !!subCategories?.length;

  return (
    <li
      className={classNames(styles.categoryItem, {
        [styles.active]: isActive
      })}
    >
      <button
        className={styles.categoryButton}
        type="button"
        onClick={handleClick}
      >
        {name}
        {haveSubCategories && (
          <FaIcon
            faName={FaIcons.chevronDown}
            className={classNames(styles.dropdownIcon, {
              [styles.open]: isListOpen
            })}
          />
        )}
      </button>
      {!!subCategories?.length && (
        <ul
          className={classNames(styles.subCategoriesList, {
            [styles.open]: isListOpen
          })}
        >
          {subCategories.map((subCategory) => {
            const key = subCategory.codes.toString().split(' ').join('-');
            const sequenceItem = {
              name,
              codes
            };

            return (
              <CategoryItem
                key={key}
                category={subCategory}
                saveCategoriesBrunch={(items) =>
                  saveCategoriesBrunch([sequenceItem, ...items])
                }
              />
            );
          })}
        </ul>
      )}
    </li>
  );
};

export default CategoryItem;
