import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AssortmentTopBar from '../../components/AssortmentTopBar/AssortmentTopBar';
import FiltersSideBar from '../../components/Filters/FiltersSideBar';
import MainLayout from '../../components/MainLayout/MainLayout';
import ProductsList from '../../components/ProductsList/ProductsList';
import { getProducts } from '../../core/api/products';
import { Product } from '../../core/types/product';
import useProductsStore from '../../store/products';
import styles from './styles.module.scss';

const AssortmentPage = () => {
  const { i18n } = useTranslation('translation', { keyPrefix: 'menu' });
  const { filters, sorting, currentBookmarkKey, toggleLoading } =
    useProductsStore();
  const [products, setProducts] = useState<Product[]>([]);
  const [currentKey, setCurrentKey] = useState<string | null>(null);

  const fetchProducts = async () => {
    toggleLoading(true);
    try {
      const fetchedProducts = await getProducts();

      if (currentBookmarkKey) {
        setProducts([...products, ...(fetchedProducts as Product[])]);
      } else setProducts(fetchedProducts as Product[]);

      setCurrentKey(currentBookmarkKey);
    } catch (error: any) {
      console.error(error);
    } finally {
      toggleLoading(false);
    }
  };

  useEffect(() => {
    if (!currentBookmarkKey || currentBookmarkKey !== currentKey)
      fetchProducts();
  }, [currentBookmarkKey, filters, sorting, i18n.language]);

  return (
    <MainLayout>
      <div className={styles.assortmentPageWrapper}>
        <div className={styles.assortmentPage}>
          <AssortmentTopBar />
          <section className={styles.pageContent}>
            <FiltersSideBar />
            <ProductsList products={products} />
          </section>
        </div>
      </div>
    </MainLayout>
  );
};

export default AssortmentPage;
