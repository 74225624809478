import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import EmptyState from '../../components/EmptyState/EmtyState';
import FaIcon from '../../components/FaIcon';
import Input from '../../components/Input/Input';
import Loader from '../../components/Loader/Loader';
import MainLayout from '../../components/MainLayout/MainLayout';
import OrderInfo from '../../components/OrderInfo/OrderInfo';
import ProductCard from '../../components/ProductCard/ProductCard';
import PromotedProductCarousel from '../../components/ProductsList/PromotedProductCarousel';
import { submitCart } from '../../core/api/cart';
import { FaIcons, LIST_VIEWS, ROUTES } from '../../core/constants';
import { SubmittedOrder } from '../../core/types/product';
import useCartStore from '../../store/shoppingCart';
import styles from './styles.module.scss';

const ShoppingBasketPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'basket' });
  const { cart, setSubmittedOrder } = useCartStore();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reference, setReference] = useState<string>('');

  const submitOrder = async (comment: string) => {
    setIsLoading(true);
    try {
      const order = await submitCart({
        comment,
        deliveryDate: '2024-11-30',
        ...(reference ? { externalDocumentNo: reference } : {})
      });
      setSubmittedOrder(order as SubmittedOrder);
      navigate(
        `${ROUTES.orderOverview}/${(order as SubmittedOrder).orderNumber}`
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MainLayout>
      <main className={styles.shoppingBasket}>
        <OrderInfo
          title={t('title')}
          backButtonText={t('back-btn-title')}
          backButtonAction={() => navigate(ROUTES.assortment)}
          titleIcon={FaIcons.basketShopping}
          points={cart?.totalPoints || 0}
          // warningText={t('long-delivery-message')}
        />
        <section className={styles.contentWrapper}>
          <div className={styles.basketContent}>
            {cart?.cartItems?.length ? (
              <>
                {cart?.cartItems?.map(({ product }) => (
                  <ProductCard
                    view={LIST_VIEWS.table}
                    product={product}
                    key={product.articleNr}
                    isBasket
                  />
                ))}
                <Formik
                  initialValues={{
                    comment: ''
                  }}
                  onSubmit={(values, actions) => {
                    submitOrder(values.comment);
                    actions.resetForm();
                  }}
                >
                  {({ values, handleChange }) => (
                    <Form className={styles.submitBlock}>
                      <div className={styles.blockHead}>
                        <label
                          className={styles.commentLabel}
                          htmlFor="comment"
                        >
                          <FaIcon faName={FaIcons.penToSquareIcon} />
                          {t('comment-field-label')}
                        </label>
                      </div>
                      <div className={styles.referenceWrapper}>
                        <label
                          className={styles.referenceLabel}
                          htmlFor="reference"
                        >
                          Uw Referentie
                        </label>
                        <Input
                          name="reference"
                          value={reference}
                          onChange={(e) => setReference(e.target.value)}
                          wrapperClassName={styles.referenceInputWrapper}
                          inputClassName={styles.referenceInput}
                        />
                      </div>
                      <div className={styles.submitField}>
                        <textarea
                          id="comment"
                          name="comment"
                          className={styles.commentField}
                          value={values.comment}
                          onChange={handleChange}
                        />
                        <button
                          className={styles.submitButton}
                          disabled={isLoading}
                        >
                          {' '}
                          {isLoading ? (
                            <Loader size={40} view="light" />
                          ) : (
                            <FaIcon faName={FaIcons.paperPlaneIcon} />
                          )}
                          <span>{t('submit-btn-title')}</span>
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </>
            ) : (
              <EmptyState text={t('empty-state-text')} />
            )}
          </div>
        </section>
        <section className={styles.promotedSlider}>
          <PromotedProductCarousel />
        </section>
      </main>
    </MainLayout>
  );
};

export default ShoppingBasketPage;
