import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FaIcon from '../../components/FaIcon';
import MainLayout from '../../components/MainLayout/MainLayout';
import OrderHeader from '../../components/OrderHeader/OrderHeader';
import Table from '../../components/Table/Table';
import { downloadInvoicePDF, getInvoices } from '../../core/api/user';
import { FaIcons } from '../../core/constants';
import { Invoice } from '../../core/types/user';
import styles from './styles.module.scss';

const conditionalRowStyles = [
  {
    when: (row: Invoice) =>
      moment(row.dueDate).isBefore(moment()) && !row.isPaid,
    style: {
      backgroundColor: '#F8D7D7 !important'
    }
  },
  {
    when: (row: Invoice) => row.isPaid,
    style: {
      backgroundColor: '#eefae5 !important'
    }
  }
];

const downloadInvoice = async (invoiceNumber: string) => {
  try {
    await downloadInvoicePDF(invoiceNumber);
  } catch (error) {
    console.error(error);
  }
};

const InvoicesPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'invoices' });
  const [invoices, setInvoices] = useState<Invoice[]>([]);

  const fetchInvoices = async () => {
    try {
      const invoices = await getInvoices();

      setInvoices(invoices as Invoice[]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  const columns = [
    {
      name: t('table.invoice-date'),
      selector: (row: Invoice) => moment(row.invoiceDate).format('DD/MM/YYYY')
    },
    {
      name: t('table.invoice-number'),
      selector: (row: Invoice) => row.invoiceNumber,
      cell: (row: Invoice) => (
        <button
          className={styles.downloadButton}
          onClick={() => downloadInvoice(row.invoiceNumber)}
        >
          <FaIcon faName={FaIcons.filePDFIcon} /> {row.invoiceNumber}{' '}
        </button>
      )
    },
    {
      name: t('table.structured-communication'),
      selector: (row: Invoice) => row.structuredCommunication
    },
    {
      name: t('table.amount'),
      selector: (row: Invoice) => '€ ' + row.totalAmount
    },
    {
      name: t('table.open-amount'),
      selector: (row: Invoice) => '€ ' + row.remainingAmount
    },
    {
      name: '',
      cell: (row: Invoice) => {
        const isExpired = moment(row.dueDate).isBefore(moment()) && !row.isPaid;

        return (
          <p
            className={classNames(styles.statusText, {
              [styles.expired]: isExpired,
              [styles.paid]: row.isPaid
            })}
          >
            {isExpired ? t('expired-invoice') : t('paid-invoice')}
          </p>
        );
      },
      grow: 2
    },
    {
      name: t('table.due-date'),
      cell: (row: Invoice) => {
        const isExpired = moment(row.dueDate).isBefore(moment());

        return (
          <span
            className={classNames(styles.dueDate, {
              [styles.expired]: isExpired
            })}
          >
            {moment(row.dueDate).format('DD/MM/YYYY')}
          </span>
        );
      }
    }
  ];

  return (
    <MainLayout>
      <div className={styles.invoicesPage}>
        <OrderHeader
          title={t('title')}
          subTitle={t('sub-title')}
          backButtonTitle={t('back-btn-title')}
          hideUserSelect
        />
        <section className={styles.invoicesContent}>
          <div className={styles.invoicesTable}>
            <Table<Invoice>
              columns={columns}
              data={invoices}
              conditionalRowStyles={conditionalRowStyles}
            />
          </div>
        </section>
      </div>
    </MainLayout>
  );
};

export default InvoicesPage;
