import i18n from '../../i18n.config';
import { ERRORS } from '../constants';
import { Category, RawCategory } from '../types/category';
import apiClient from './client';

type CategoriesResponse = {
  tree: Category[];
  list: RawCategory[];
};

export const getCategories = async (): Promise<CategoriesResponse> => {
  const { statusCode, response } =
    await apiClient.get<CategoriesResponse>('api/categories');

  if (statusCode === 200) {
    return response;
  }

  throw new Error(i18n.t(ERRORS.default));
};
